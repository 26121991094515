




















export default {
  name: 'PSingleCardImage',
  props: {
    img: {
      type: String,
      // eslint-disable-next-line
      default: () => 'img/logo.png'
    }
  }
}
